/** @see https://usehooks-ts.com/react-hook/use-copy-to-clipboard */

import { useCallback, useState } from "react";

export function useCopyToClipboard(): [string | undefined, (text: string) => Promise<boolean>] {
  const [copiedText, setCopiedText] = useState<undefined | string>();

  const copy = useCallback(async (text: string) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      // eslint-disable-next-line unicorn/no-useless-undefined
      setCopiedText(undefined);
      return false;
    }
  }, []);

  return [copiedText, copy];
}
